import React, { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import './SearchTipContents.scss';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import ModalContainer from '../Modals/ModalContainer';
import { getOrganisationTags } from '../../actions/tags';
import { categoryActions } from '../../actions';

const SearchTipContents = ({ dispatch }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const categories = useSelector(state => state.categories.categories);
  const categoriesLoading = useSelector(state => state.categories.loading);
  const tagsLibrary = useSelector(state => state.tags.tagsLibrary);
  const tagsLibraryLoading = useSelector(state => state.tags.loading);

  useEffect(() => {
    if (!categories.length && categoriesLoading) dispatch(categoryActions.getCategories());
  }, [categories]);

  useEffect(() => {
    if (!tagsLibrary.length && tagsLibraryLoading) dispatch(getOrganisationTags());
  }, [tagsLibrary]);

  return (
    <ModalContainer>
      <div className="search-tip-modal-container">
        <Tabs selectedIndex={activeTabIndex} onSelect={tabIndex => setActiveTabIndex(tabIndex)}>
          <TabList>
            <Tab>Search Tips</Tab>
            <Tab>Categories</Tab>
            <Tab>Tags</Tab>
          </TabList>

          <TabPanel>
            <div className="c-modal__body">
              <div className="search-tip-contents">
                <ol>
                  <li>
                    <p className="bold">Add filters to your search</p>
                    <p>The filters that can be used for Documents are:</p>
                    <ul>
                      <li>Plan Number</li>
                      <li>Lot Number</li>
                      <li>Type</li>
                      <li>Tag</li>
                    </ul>
                    <p>The filters that can be used for Invoices are:</p>
                    <ul>
                      <li>Plan Number</li>
                      <li>Lot Number</li>
                      <li>Contractor</li>
                      <li>Status</li>
                      <li>Invoice Number</li>
                      <li>Invoice Amount</li>
                      <li>Action User</li>
                    </ul>
                  </li>

                  <li>
                    <p className="bold mt-4">To narrow your search to specific terms you can use text search</p>
                    <p>
                      For example, to search for a Sinking Fund Plan, type in 'sinking' and then click "Text/Image Search for 'sinking'" to show
                      results that include the text in its name or within the document.
                    </p>
                  </li>

                  <li>
                    <p className="bold mt-4">To join search terms, add the word 'WITH' between your keywords</p>
                    <p>i.e. keyword WITH keyword</p>
                    <p>For example, 'sinking WITH fund'</p>
                    <p>
                      <strong>Note:</strong> WITH must be in capitals
                    </p>
                  </li>
                </ol>

                <p className="bold">
                  For more information about search visit the&nbsp;
                  <a href="https://help.smata.com/" target="_blank" rel="noopener noreferrer">
                    help.smata.com
                  </a>
                </p>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="c-modal__body">
              <div className="search-tip-contents">
                <p className="bold">The following is a list of categories that are used for document filling and searchable:</p>
                <ul>
                  {categories?.length > 0 ? (
                    categories.map(category => <li key={category.value}>{category.label}</li>)
                  ) : (
                    <span>No categories available</span>
                  )}
                </ul>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="c-modal__body">
              <div className="search-tip-contents">
                <p className="bold">The following is a list of tags that are used for document filling and searchable:</p>
                <ul>{tagsLibrary?.length > 0 ? tagsLibrary.map(tag => <li key={tag.id}>{tag.name}</li>) : <span>No tags available</span>}</ul>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </ModalContainer>
  );
};

export default connect(state => state.modal)(SearchTipContents);

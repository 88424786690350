import React from 'react';
import notFoundImage from '../../images/illustrations/no-files.svg';
import './NotFound.scss';

export const NotFound = ({ text = 'No documents found' }) => (
  <div className="dms-not-found">
    <div className="dms-not-found-content">
      <img src={notFoundImage} alt={text} />
      <h2>{text}</h2>
    </div>
  </div>
);

import React from 'react';
import './invoiceFundingStatusExplanation.scss';

export const invoiceFundingStatusExplanation = () => (
  <div>
    <table id="invoice-status-explanation-table">
      <colgroup>
        <col style={{ width: '25%' }} />
        <col style={{ width: '75%' }} />
      </colgroup>
      <thead />
      <tbody>
        <tr>
          <td>
            <div className="invoice-status on-hold">CREATED</div>
          </td>
          <td>Invoice Payment Plan Created</td>
        </tr>
        <tr>
          <td>
            <div className="invoice-status under-review">ACTIVE</div>
          </td>
          <td>Invoice funded</td>
        </tr>
        <tr>
          <td>
            <div className="invoice-status approved">COMPLETED</div>
          </td>
          <td>Invoice payment received, and funding arrangement settled</td>
        </tr>
        <tr>
          <td>
            <div className="invoice-status cancelled">IN DEFAULT</div>
          </td>
          <td>Invoice payment Plan is overdue, no payment received</td>
        </tr>
      </tbody>
    </table>
  </div>
);

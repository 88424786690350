import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { categoryActions, flashActions, locationActions, modalActions } from '../../actions';
import { axiosInstance } from '../../utils';
import { bulkDocumentDownloadConstants } from '../../constants';
import ModalContainer from './ModalContainer';

import './CreateEditBulkDocumentDownloadModal.scss';

const CreateEditBulkDocumentDownloadModal = ({ item, onCreateEditBulkDocumentDownloadSuccess }) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {},
  });

  const [allCategoriesOptionDisable, setAllCategoriesOptionDisable] = useState(false);
  const [allCategoriesExceptInvoiceOptionDisable, setAllCategoriesExceptInvoiceOptionDisable] = useState(false);
  const [categoriesSelectDisabled, setCategoriesSelectDisabled] = useState(false);

  console.log(item);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'all_categories' && type === 'change') {
        handleAllCategoriesChange(value);
      }

      if (name === 'all_categories_except_invoice' && type === 'change') {
        handleAllCategoriesExceptInvoiceChange(value);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (item) {
      setValue('all_categories', item.all_categories);
      setValue('all_categories_except_invoice', item.all_categories_except_invoice);
      setValue('location', item.account);
      setValue('priority', item.priority);
      setValue('categories', item.categories);

      if (item.all_categories) {
        handleAllCategoriesChange({
          all_categories: item.all_categories,
          all_categories_except_invoice: item.all_categories_except_invoice,
        });
      }

      if (item.all_categories_except_invoice) {
        handleAllCategoriesExceptInvoiceChange({
          all_categories: item.all_categories,
          all_categories_except_invoice: item.all_categories_except_invoice,
        });
      }
    }
  }, []);

  const handleAllCategoriesChange = value => {
    if (value.all_categories) {
      setAllCategoriesExceptInvoiceOptionDisable(true);
      setValue('all_categories_except_invoice', false);
      setCategoriesSelectDisabled(true);
      setValue('categories', []);
    } else {
      setAllCategoriesExceptInvoiceOptionDisable(false);

      if (!value.all_categories_except_invoice) {
        setCategoriesSelectDisabled(false);
      }
    }
  };

  const handleAllCategoriesExceptInvoiceChange = value => {
    if (value.all_categories_except_invoice) {
      setAllCategoriesOptionDisable(true);
      setValue('all_categories', false);
      setCategoriesSelectDisabled(true);
      setValue('categories', []);
    } else {
      setAllCategoriesOptionDisable(false);

      if (!value.all_categories) {
        setCategoriesSelectDisabled(false);
      }
    }
  };

  const dispatch = useDispatch();
  const onSubmit = async data => {
    const payload = {
      account_id: data.location?.account_id,
      priority: parseInt(data.priority, 10),
    };

    if (data.categories && data.categories.length > 0) {
      payload.category_ids = data.categories.map(c => c.id);
    }
    if (data.all_categories) {
      payload.all_categories = true;
    }
    if (data.all_categories_except_invoice) {
      payload.all_categories_except_invoice = true;
    }

    if (item) {
      await axiosInstance
        .put(`${bulkDocumentDownloadConstants.BASE_PATH}/${item.id}`, payload)
        .then(() => {
          dispatch(flashActions.showSuccess('Done!'));
          dispatch(modalActions.hideModal());
          onCreateEditBulkDocumentDownloadSuccess();
        })
        .catch(error => {
          dispatch(flashActions.showError(error));
        });
    } else {
      await axiosInstance
        .post(bulkDocumentDownloadConstants.BASE_PATH, payload)
        .then(() => {
          dispatch(flashActions.showSuccess('Created successfully!'));
          dispatch(modalActions.hideModal());
          onCreateEditBulkDocumentDownloadSuccess();
        })
        .catch(error => {
          dispatch(flashActions.showError(error));
        });
    }
  };

  return (
    <ModalContainer
      title={item ? 'Edit Bulk Download' : 'Create Bulk Download'}
      reactModalProps={{
        shouldCloseOnOverlayClick: false,
        className: 'c-modal__container c-modal__container--lg',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={isSubmitting}>
          <div className="c-modal__body">
            <div className="form__group">
              <div className="form__control">
                <label htmlFor="location">Plan Number</label>
              </div>
              <Controller
                name="location"
                control={control}
                rules={{ required: 'Can not be blank' }}
                render={({ field }) => (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    loadOptions={debounce((inputValue, callback) => locationActions.getLocationOptions(1, 20, inputValue, callback), 500)}
                    defaultOptions
                    blurInputOnSelect
                    classNamePrefix="react-select"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                  />
                )}
              />
              {errors.location && <div className="invalid-feedback">{errors.location.message}</div>}
            </div>

            <div className="form__group">
              <div className="form__control">
                <label htmlFor="categories">Categories</label>
              </div>
              <Controller
                disabled={categoriesSelectDisabled}
                name="categories"
                control={control}
                rules={{
                  validate: {
                    required: value => {
                      if (allCategoriesOptionDisable || allCategoriesExceptInvoiceOptionDisable) return true;
                      if (!value || value.length === 0) return 'Can not be blank';
                      return true;
                    },
                  },
                }}
                render={({ field }) => (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    loadOptions={debounce((inputValue, callback) => categoryActions.getCategoryOptions(inputValue, callback), 500)}
                    defaultOptions
                    blurInputOnSelect
                    classNamePrefix="react-select"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    isMulti
                    isDisabled={categoriesSelectDisabled}
                  />
                )}
              />
              {errors.categories && <div className="invalid-feedback">{errors.categories.message}</div>}
            </div>

            <div className="form__group">
              <div className={`form__control ${allCategoriesOptionDisable ? 'disabled' : ''}`}>
                <label className="checkbox-container" htmlFor="all_categories">
                  All categories
                  <input
                    {...register('all_categories')}
                    type="checkbox"
                    id="all_categories"
                    name="all_categories"
                    disabled={allCategoriesOptionDisable}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>

            <div className="form__group">
              <div className={`form__control ${allCategoriesExceptInvoiceOptionDisable ? 'disabled' : ''}`}>
                <label className="checkbox-container" htmlFor="all_categories_except_invoice">
                  All categories except Invoice
                  <input
                    {...register('all_categories_except_invoice')}
                    type="checkbox"
                    id="all_categories_except_invoice"
                    name="all_categories_except_invoice"
                    disabled={allCategoriesExceptInvoiceOptionDisable}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>

            <div className="form__group">
              <div className="form__control">
                <label htmlFor="priority">
                  Priority <small>(higher number for higher priority)</small>
                </label>
              </div>

              <div className="form__control">
                <input
                  {...register('priority', { required: 'Can not be blank' })}
                  type="number"
                  onWheel={e => e.currentTarget.blur()}
                  placeholder="Enter a higher number for higher priority"
                  id="priority"
                  name="priority"
                  defaultValue={1}
                />
              </div>
              {errors.priority && <div className="invalid-feedback">{errors.priority.message}</div>}
            </div>
            <div className="form__group">
              <span className="alert text--warning">
                Your download may not be completed within business hours, you can check back here for your download link, otherwise, we will notify
                you when it is ready.
              </span>
            </div>
          </div>
          <div className="c-modal__footer">
            <button type="button" className="button button--link-dark" disabled={isSubmitting} onClick={() => dispatch(modalActions.hideModal())}>
              Cancel
            </button>
            <button type="submit" className="button button--primary" style={{ marginLeft: '10px', minWidth: '100px' }}>
              {item ? 'Save' : 'Create'}
            </button>
          </div>
        </fieldset>
      </form>
    </ModalContainer>
  );
};

export default CreateEditBulkDocumentDownloadModal;

import React from 'react';
import { Tooltip } from 'react-tippy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { setSortOptions } from '../../actions/dms';
import { invoiceFundingStatusExplanation } from './InvoiceView/invoiceFundingStatusExplanation';
import { invoiceStatusExplanation } from './InvoiceView/invoiceStatusExplanation';

import './SortOptions.scss';

export const SortOptions = ({ visible, viewType, allSelected, setAllSelected }) => {
  const dispatch = useDispatch();
  const { order, sort } = useSelector(state => state.dms.sortOptions);
  const currentUser = useSelector(state => state.auth.currentUser);

  if (!visible) return null;

  const changeSortOption = (event, option) => {
    event.preventDefault();

    if (order === option) {
      dispatch(setSortOptions(option, sort === 'asc' ? 'desc' : 'asc'));
    } else {
      dispatch(setSortOptions(option, 'asc'));
    }
  };

  const orderIndicator = columnName => {
    if (order === columnName) {
      if (sort === 'asc') {
        return 'icon-after icon-chevron-down-dark active';
      }

      return 'icon-after icon-chevron-down-dark';
    }

    return 'icon-after icon-chevron-down-dark';
  };

  if (currentUser?.isContractor) {
    return (
      <div className="document-table__headers--for-contractor">
        <div className="document-table__header document-table__header__select-all-checkbox checkboxes-field ">
          <div className="option">
            <input
              id="selected-all"
              name="selected-all"
              onChange={event => setAllSelected(event)}
              checked={allSelected}
              type="checkbox"
            />
            <label htmlFor="selected-all" />
          </div>
        </div>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'sp_number')}
          className={`document-table__header document-table__header__sp-number ${orderIndicator('sp_number')}`}
        >
          Plan Number
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'invoice.status')}
          className={`document-table__header document-table__header__invoice-status ${orderIndicator('invoice.status')}`}
        >
          <Tooltip arrow html={invoiceStatusExplanation()} position="bottom" animation="fade" theme="light">
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
          <span style={{ marginLeft: '5px' }}>Status</span>
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'invoice.invoice_number')}
          className={`document-table__header document-table__header__invoice-number ${orderIndicator('invoice.invoice_number')}`}
        >
          No.
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'invoice.invoiced_price')}
          className={`document-table__header document-table__header__invoice-amount ${orderIndicator('invoice.invoiced_price')}`}
        >
          Amount
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'added_date')}
          className={`document-table__header document-table__header__invoice-date ${orderIndicator('added_date')}`}
        >
          Added
        </a>

        <a
          href="#"
          className="document-table__header document-table__header__funding-status"
        >
          <Tooltip arrow html={invoiceFundingStatusExplanation()} position="bottom" animation="fade" theme="light">
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
          <span style={{ marginLeft: '5px' }}>Funding Status</span>
        </a>

        <a
          href="#"
          className="document-table__header document-table__header__settlement-date"
        >
          <span style={{ marginLeft: '5px' }}>Settlement Due</span>
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'invoice.payment_date')}
          className={`document-table__header document-table__header__invoice-paid-date ${orderIndicator('invoice.payment_date')}`}
        >
          Date Paid
        </a>

        <div className="document-table__header document-table__header__action-buttons" />
      </div>
    );
  }

  if (viewType === 'documents') {
    return (
      <div className="dms-sort-options document-view">
        <div className="dms-sort-option checkboxes-field doc-select-all">
          <div className="option">
            <input
              id="selected-all"
              name="selected-all"
              onChange={event => setAllSelected(event)}
              checked={allSelected}
              type="checkbox"
            />
            <label htmlFor="selected-all" />
          </div>
        </div>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'sp_number')}
          className={`dms-sort-option doc-sort-sp ${orderIndicator('sp_number')}`}
        >
          Plan Number
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'category')}
          className={`dms-sort-option doc-sort-type ${orderIndicator('category')}`}
        >
          Type
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'filename')}
          className={`dms-sort-option doc-sort-name ${orderIndicator('filename')}`}
        >
          Name
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'added_date')}
          className={`dms-sort-option doc-sort-date ${orderIndicator('added_date')}`}
        >
          Added
        </a>
        <div className="dms-sort-option doc-actions-header" />
      </div>
    );
  }

  if (viewType === 'invoices') {
    return (
      <div className="dms-sort-options invoice-view">
        <div className="dms-sort-option checkboxes-field invoice-select-all">
          <div className="option">
            <input
              id="selected-all"
              name="selected-all"
              onChange={event => setAllSelected(event)}
              checked={allSelected}
              type="checkbox"
            />
            <label htmlFor="selected-all" />
          </div>
        </div>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'sp_number')}
          className={`dms-sort-option invoice-sp-number ${orderIndicator('sp_number')}`}
        >
          Plan Number
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'contractor.name')}
          className={`dms-sort-option invoice-sort-contractor ${orderIndicator('contractor.name')}`}
        >
          Contractor
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'invoice.status')}
          className={`dms-sort-option invoice-sort-status ${orderIndicator('invoice.status')}`}
        >
          <Tooltip arrow html={invoiceStatusExplanation()} position="bottom" animation="fade" theme="light">
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
          <span style={{ marginLeft: '5px' }}>Status</span>
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'invoice.invoice_number')}
          className={`dms-sort-option invoice-sort-number ${orderIndicator('invoice.invoice_number')}`}
        >
          No.
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'invoice.invoiced_price')}
          className={`dms-sort-option invoice-sort-amount ${orderIndicator('invoice.invoiced_price')}`}
        >
          Amount
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'added_date')}
          className={`dms-sort-option invoice-sort-date ${orderIndicator('added_date')}`}
        >
          Added
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'currently_with_user_details.initials')}
          className={`dms-sort-option invoice-sort-currently-with ${order === 'currently_with_user_details.initials' ? 'active' : ''}`}
        >
          <Tooltip
            arrow
            title="Invoice is in this user's tasks to review"
            position="bottom"
            animation="fade"
            theme="light"
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
          <span style={{ marginLeft: '5px' }}>Action</span>
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'currently_with_user_details.time_with')}
          className={`dms-sort-option sort-currently-time-with time-with-header ${orderIndicator('currently_with_user_details.time_with')}`}
        >
          <Tooltip
            arrow
            title="This is the amount of time the invoice has been with the current action user."
            position="bottom"
            animation="fade"
            theme="light"
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
          <span style={{ marginLeft: '5px' }}>TIME</span>
        </a>

        <a
          href="#"
          onClick={event => changeSortOption(event, 'invoice.payment_date')}
          className={`dms-sort-option invoice-paid-date ${orderIndicator('invoice.payment_date')}`}
        >
          Date Paid
        </a>

        <div className="dms-sort-option invoice-actions-header" />
      </div>
    );
  }
};

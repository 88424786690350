import './invoiceStatusExplanation.scss';

export const invoiceStatusExplanation = () => (
  <div>
    <table id="invoice-status-explanation-table">
      <colgroup>
        <col style={{ width: '25%' }} />
        <col style={{ width: '75%' }} />
      </colgroup>
      <thead />
      <tbody>
        <tr>
          <td>
            <div className="invoice-status new">NEW</div>
          </td>
          <td>
            Recently added and ready to be reviewed by the First Approver. Requires details to be added (mandatory
            details are Plan Number, Creditor, Invoice Amount, Invoice Number, Invoice Date, Row Items).
          </td>
        </tr>
        <tr>
          <td>
            <div className="invoice-status under-review">UNDER REVIEW</div>
          </td>
          <td>In the invoice approval process and is with an approver to action.</td>
        </tr>
        <tr>
          <td>
            <div className="invoice-status on-hold">ON HOLD</div>
          </td>
          <td>
            Internal approver has restricted the invoice from moving through the invoice approval process. Check the
            invoice history for comments/reasons from the user that made this action.
          </td>
        </tr>
        <tr>
          <td>
            <div className="invoice-status cancelled">CANCELLED</div>
          </td>
          <td>Removed from the invoice processing workflow. Can be re-opened.</td>
        </tr>
        <tr>
          <td>
            <div className="invoice-status deleted">DELETED</div>
          </td>
          <td>Deleted from the document management system. Cannot be re-opened.</td>
        </tr>
        <tr>
          <td>
            <div className="invoice-status duplicate">DUPLICATE</div>
          </td>
          <td>Invoice details match another invoice in the system (invoice number, invoice amounts, creditor business number).</td>
        </tr>
        <tr>
          <td>
            <div className="invoice-status rejected">REJECTED</div>
          </td>
          <td>
            Invoice has been rejected by an internal approver and sent back to the service provider to re-submit. You
            can check the invoice history for comments.
          </td>
        </tr>
        <tr>
          <td>
            <div className="invoice-status processing">PROCESSING</div>
          </td>
          <td>Approved for payment and is being processed to the accounting platform.</td>
        </tr>
        <tr>
          <td>
            <div className="invoice-status processing-failed">PROCESSING FAILED</div>
          </td>
          <td>Approved for payment and but failed to process to the accounting platform.</td>
        </tr>
        <tr>
          <td>
            <div className="invoice-status approved">APPROVED FOR PAYMENT</div>
          </td>
          <td>Approved by required approvers and transfered to accounting system ready for payment.</td>
        </tr>
        <tr>
          <td>
            <div className="invoice-status scheduled-payment">SCHEDULED FOR PAYMENT</div>
          </td>
          <td>
            Future date set for invoice to be transferred to accounting system for payment. Hover over calendar icon in
            the right-hand column for date.
          </td>
        </tr>
        <tr>
          <td>
            <div className="invoice-status paid">PAID</div>
          </td>
          <td>Invoice has been paid.</td>
        </tr>
      </tbody>
    </table>
  </div>
);

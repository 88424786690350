import { userConstants } from '../constants';

/**
 * _THIS IS A QUICK FIX FOR `BAU-1356`_
 *
 * This function is to validating wether the user role is restricted for certain permissions.
 *
 * @param {string} role Role of the User. Eg: strata_manager
 * @param {string} permission User Permission to check with. Eg: invoice.override
 * @returns Boolean
 */
export const isUserRestricted = (role, permission) => {
  let isRestricted = false;
  const restrictedPermissions = userConstants.RESTRICTED_PERMISSIONS_FOR_ROLES;

  Object.keys(restrictedPermissions).forEach(key => {
    if (key === role && restrictedPermissions[key].includes(permission)) {
      isRestricted = true;
    }
  });

  return isRestricted;
};

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import formatBytes from '../helpers/formatBytes';
import { FileType } from '../../FileType';
import './UploadedFiles.scss';

export const UploadedFiles = props => {
  const { removeFileHandler, file, index } = props;

  if (!file) {
    return null;
  }
  return (
    <div className="file-upload-top">
      <FileType type={file.type} />
      <div className="uploaded-top-details">
        <p className="file-name">{file.filename}</p>
        <p className="file-size">{formatBytes(file.file_size)}</p>
      </div>
      <div className="uploaded-top-action-buttons" style={{ textAlign: 'right' }}>
        <button
          type="button"
          className="button button--link-dark button--padding-none"
          onClick={() => removeFileHandler(index)}
        >
          <FontAwesomeIcon icon={faTimes} color="#000" />
        </button>
      </div>
    </div>
  );
};
